/* globals process:false */
import { clearAllBodyScrollLocks, enableBodyScroll } from 'body-scroll-lock';
import SimpleBar from 'SimpleBar';

import getElements from '@utils/getElements';

/**
 * A multi-level navigation with infinite nesting level support.
 */
class MultiLevelNavigation {
  /**
   * Create a new instance of {MultiLevelNavigation}.
   * @param {HTMLElement} element
   * @param {Object} options
   */
  constructor(element, options) {
    this.container = element;
    this.options = options;

    this.activeClass = options.activeClass;
    this.overlayElement = options.overlayElement;
    this.toggleButtonElement = options.toggleButtonElement;
    this.scrollableSelector = options.scrollableSelector;

    this.backButtons = getElements(element, options.backSelector);
    this.browseButtons = getElements(element, options.browseSelector);
    this.closeButtons = getElements(element, options.closeSelector);
    this.navBlocks = getElements(element, options.blockSelector);
    this.navLinks = getElements(element, options.linkSelector);

    this.current = null;
    this.currentScrollbar = null;

    this.stack = [];

    this.backButtons.forEach(btnBack => {
      btnBack.addEventListener('click', event => {
        event.preventDefault();
        this.openPreviousBlock();
      });
    });

    this.browseButtons.forEach(btnBrowse => {
      btnBrowse.addEventListener('click', event => {
        event.preventDefault();

        const navBlock = this.getBlock(btnBrowse.dataset.target);

        // If there is no target block then "click" the link.
        if (!navBlock) {
          const navLink = this.getSibling(event.currentTarget, this.options.linkSelector);

          if (navLink) {
            navLink.click();
          }
        } else {
          this.addActiveAsPreviousBlock();
          this.openBlock(navBlock, event.currentTarget);
        }
      });
    });

    this.navLinks.forEach(navLink => {
      navLink.addEventListener('click', event => {
        // If link is empty or hash then make it behave like a browse button.
        const hrefValue = navLink.getAttribute('href');

        if (hrefValue === '' || hrefValue === '#') {
          event.preventDefault();

          const btnBrowse = this.getSibling(event.currentTarget, this.options.browseSelector);

          if (btnBrowse) {
            btnBrowse.click();
          }
        }
      });
    });

    this.closeButtons.forEach(el => {
      el.addEventListener('click', event => {
        event.preventDefault();
        this.closeNavigation();
      });
    });
  }

  /**
   * Get the current active block id.
   * @returns {String}
   */
  get current() {
    // eslint-disable-next-line no-underscore-dangle
    return this._current;
  }

  /**
   * Set the current active block id.
   * @param {String} value
   */
  set current(value) {
    // eslint-disable-next-line no-underscore-dangle
    this._current = value;

    // eslint-disable-next-line no-underscore-dangle
    debug('current changed', this._current);
  }

  /**
   * Get the current block id stack.
   * @returns {[String]}
   */
  get stack() {
    // eslint-disable-next-line no-underscore-dangle
    return this._stack;
  }

  /**
   * Set the current block id stack.
   * @param {[String]} value
   */
  set stack(value) {
    // eslint-disable-next-line no-underscore-dangle
    this._stack = value;

    // eslint-disable-next-line no-underscore-dangle
    debug('updated stack', this._stack);
  }

  /**
   * Push the current active block to the stack.
   */
  addActiveAsPreviousBlock() {
    const activeBlocks = this.navBlocks.filter(
      el => el.classList.contains(this.activeClass) && el.dataset.level,
    );

    if (activeBlocks && activeBlocks.length) {
      // Open lowest level active block.
      const activeBlock = activeBlocks[activeBlocks.length - 1];
      this.addPreviousBlock(activeBlock.dataset.id);
    } else {
      const mainBlock = this.navBlocks[0];
      this.stack.push(mainBlock.dataset.id);
      debug('updated stack', this.stack);
    }
  }

  /**
   * Add the specified block to the stack.
   * @param {String} id
   */
  addPreviousBlock(id) {
    // Ensure data variable.
    this.stack.push(id);
    debug('updated stack', this.stack);
  }

  /**
   * Close the current block.
   */
  closeCurrent() {
    const currentBlock = this.getBlock(this.current);

    currentBlock.classList.remove(this.activeClass);

    // Enable scroll for body.
    const scrollable = currentBlock.querySelector(this.scrollableSelector);
    enableBodyScroll(scrollable);
  }

  /**
   * Close the navigation completely, also resets the current and stack data.
   */
  closeNavigation() {
    // Remove all items from stack.
    this.stack = [];

    // Clear current value.
    this.current = null;

    this.container.classList.remove(this.activeClass);
    this.overlayElement.classList.remove(this.activeClass);
    this.navBlocks.forEach(navBlock => {
      navBlock.classList.remove(this.activeClass);
    });

    clearAllBodyScrollLocks();

    // Disable keyboard navigation for entire side navigation.
    this.disableKeyboardNavigation(this.container);
  }

  /**
   * Disable keyboard navigation for descendants of element.
   * @param {HTMLElement} element
   */
  disableKeyboardNavigation(element) {
    const tabbable = Array.from(element.querySelectorAll('[tabindex]'));

    tabbable.forEach(el => {
      el.setAttribute('tabindex', '-1');
    });
  }

  /**
   * Enable keyboard navigation for descendants of element.
   * @param {HTMLElement} element
   */
  enableKeyboardNavigation(element) {
    const tabbable = Array.from(element.querySelectorAll('[tabindex]'));

    tabbable.forEach(el => {
      el.setAttribute('tabindex', '');
    });
  }

  /**
   * Focus the first focusable descendant in the specified element.
   * @param {HTMLElement} element
   */
  focusFirstElement(element) {
    const focusable = element.querySelector('[tabindex]');
    focusable.focus();
  }

  /**
   * Get the first sibling that matches the selector.
   * @param {HTMLElement} element
   * @param {String} selector
   */
  getSibling(element, selector) {
    return element.parentNode.querySelector(selector);
  }

  /**
   * Manually destroy the SimpleBar instance.
   */
  destroyeSimplebar() {
    const simpleBarElement = this.currentScrollbar.el;
    const content = simpleBarElement.querySelector('.simplebar-content');
    while (content.firstChild) {
      simpleBarElement.parentElement.appendChild(content.firstChild);
    }
    simpleBarElement.parentElement.removeChild(simpleBarElement);
  }

  /**
   * When nav reopens the navBlock missing the wrapper ul, this function will add it.
   * @param {HTMLElement} navBlock
   */
  checkUl(navBlock) {
    const wrapperUl = navBlock.querySelector(':scope > ul');
    const listItems = navBlock.querySelectorAll(':scope > li');
    // Check if the first li element has a parent with the class 'side-navigation__items'
    if (!wrapperUl) {
      const newUl = document.createElement('ul');
      newUl.classList.add('side-navigation__items', 'js-nav-scrollable');

      listItems.forEach(li => {
        newUl.appendChild(li);
      });

      navBlock.appendChild(newUl);
    }
  }

  /**
   * Open the specified block, also handles clicking through to the link if block not found.
   * @param {HTMLElement} navBlock
   */
  openBlock(navBlock) {
    // add active class to target block.
    navBlock.classList.add(this.activeClass);

    if (this.currentScrollbar) {
      this.destroyeSimplebar();
      this.currentScrollbar = null;
    }

    this.checkUl(navBlock);
    if (
      !('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
    ) {
      const scrollSelector = navBlock.querySelector('ul.js-nav-scrollable:first-of-type');
      this.currentScrollbar = new SimpleBar(scrollSelector, { autoHide: false });
    }

    // Disable scroll for body.
    // const scrollable = navBlock.querySelector(this.scrollableSelector);
    // disableBodyScroll(scrollable, { reserveScrollBarGap: true });

    // Disable keyboard navigation for entire side navigation.
    this.disableKeyboardNavigation(this.container);

    // Then enable keyboard navigation for the new navigation block.
    this.enableKeyboardNavigation(navBlock);

    // Then re-disable on child blocks (bit dirty...)
    const childBlocks = Array.from(navBlock.querySelectorAll(this.options.blockSelector));

    childBlocks.forEach(childBlock => {
      this.disableKeyboardNavigation(childBlock);
    });

    // Finally, set first focusable element in block as focus.
    // this.focusFirstElement(navBlock);

    // set current.
    this.current = navBlock.dataset.id;
  }

  /**
   * Close the current block and show the previous.
   */
  openPreviousBlock() {
    if (!this.stack || !this.stack.length) {
      this.closeNavigation();
      return;
    }

    // Hide current.
    this.closeCurrent();

    // Ensure data variable.
    const previous = this.stack.pop();
    debug('updated stack', this.stack);

    const navBlock = this.getBlock(previous);
    this.openBlock(navBlock);
  }

  /**
   * Toggle the visibility of the navigation.
   */
  toggleNavigation() {
    // Toggle navigation visibility.
    this.container.classList.toggle(this.activeClass);

    // toggle menu-button state
    this.toggleButtonElement.classList.toggle(this.activeClass);
    const body = document.querySelector('body');

    // Set overlay visibility based on navigation visibility.
    if (this.container.classList.contains(this.activeClass)) {
      this.overlayElement.classList.add(this.activeClass);

      // Disable scroll for body.
      if (body) {
        body.setAttribute(
          'style',
          'position:fixed;top: 0;right: 0;left: 0;bottom: 0;overflow-y: scroll;',
        );
      }

      const cultureDropdown = document.querySelector('.js-culture-selector-drop');
      if (
        cultureDropdown &&
        cultureDropdown.classList.contains('is-active') &&
        ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
      ) {
        cultureDropdown.classList.remove('is-active');
      }

      // Show active block if set.
      let activeBlock = this.navBlocks.find(block => block.matches('[data-is-active="true"]'));
      if (!activeBlock) {
        [activeBlock] = this.navBlocks;
      }
      if (activeBlock) {
        const ids = this.getBlockTree(activeBlock);
        this.setStack(ids);
        // Disable keyboard navigation for entire side navigation.
        this.disableKeyboardNavigation(this.container);

        // Then enable keyboard navigation for the new navigation block.
        this.enableKeyboardNavigation(activeBlock);

        // Then re-disable on child blocks (bit dirty...)
        const childBlocks = Array.from(activeBlock.querySelectorAll(this.options.blockSelector));

        childBlocks.forEach(childBlock => {
          this.disableKeyboardNavigation(childBlock);
        });
      }
    } else {
      this.overlayElement.classList.remove(this.activeClass);
      this.navBlocks.forEach(navBlock => {
        navBlock.classList.remove(this.activeClass);
      });

      // Ensure body is scrollable again.
      if (body) {
        body.setAttribute('style', '');
      }

      // Disable keyboard navigation for entire side navigation.
      // this.disableKeyboardNavigation(this.container);
    }

    // this.focusFirstElement(this.container.querySelector('.js-nav-block'));
  }

  /**
   * Get the stack array for the specified nav block.
   * @param {HTMLElement} child
   * @returns {[String]}
   */
  getBlockTree(child) {
    // Create array to hold ids.
    const tree = [child.dataset.id];

    // Set
    let current = this.getBlockParent(child);

    while (current) {
      // Add parent to array.
      tree.push(current.dataset.id);

      // Retrieve next parent.
      current = this.getBlockParent(current);
    }

    // Reverse order so root first, then children.
    tree.reverse();

    // Return complete tree.
    return tree;
  }

  /**
   * Get the nav block for the specified id.
   * @param {String} id
   * @returns {HTMLElement}
   */
  getBlock(id) {
    return this.navBlocks.find(nb => nb.dataset.id === id);
  }

  /**
   * Get the parent nav block for the specified nav block.
   * @param {HTMLElement} navBlock
   * @returns {HTMLElement}
   */
  getBlockParent(navBlock) {
    return this.getBlock(navBlock.dataset.parent);
  }

  /**
   * Set the current stack to the specified stack array.
   * @param {[String]} ids
   */
  setStack(ids) {
    // Set stack to ids other than final.
    this.stack = ids.slice(0, ids.length - 1);

    // Add active class to all blocks in stack.
    this.navBlocks
      .filter(nb => this.stack.includes(nb.dataset.id))
      .forEach(nb => {
        nb.classList.add(this.activeClass);
      });

    // Open lowest level.
    const child = ids[ids.length - 1];
    const navBlock = this.getBlock(child);
    this.openBlock(navBlock);
  }
}

/**
 * Log arguments to console in development mode.
 * @param args
 */
function debug(...args) {
  if (process.env.NODE_ENV === 'production') {
    return;
  }
  // eslint-disable-next-line no-console
  console.log(...args);
}

export default MultiLevelNavigation;
