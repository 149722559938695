import whenDomReady from 'when-dom-ready';

import EventDispatcher from '@utils/EventDispatcher';

function initialise() {
  const toggleButtons = Array.from(document.querySelectorAll('.js-search-toggle'));

  toggleButtons.forEach(element => {
    element.addEventListener('click', event => {
      event.preventDefault();

      /** Dispatch event on root element to trigger React to show the overlay. */
      const hotTopics = element.getAttribute('data-hot-topics');
      const hotTopicsData = JSON.parse(hotTopics.replace(/'/g, '"'));
      EventDispatcher.dispatchEvent('search.toggle', { detail: hotTopicsData });
    });
  });
}

whenDomReady().then(initialise);
