import LazyLoad from 'vanilla-lazyload';
import whenDomReady from 'when-dom-ready';

function initialise() {
  // eslint-disable-next-line no-unused-vars
  const lazyLoadInstance = new LazyLoad({
    elements_selector: '.js-lazy',
  });
}

whenDomReady().then(initialise);
