import handorgel from 'handorgel';
import whenDomReady from 'when-dom-ready';

function initialiseInside(element) {
  const accordions = element.querySelectorAll('.js-accordion');
  const accordionsLength = accordions.length;
  for (let i = 0; i < accordionsLength; i += 1) {
    // eslint-disable-next-line no-unused-vars, new-cap
    const accordion = new handorgel(accordions[i], {
      // whether multiple folds can be opened at once
      multiSelectable: false,
      // whether the folds are collapsible
      collapsible: true,
      // whether ARIA attributes are enabled
      ariaEnabled: true,
      // whether W3C keyboard shortcuts are enabled
      keyboardInteraction: true,
      // whether to loop header focus (sets focus back to first/last header when end/start reached)
      carouselFocus: true,
      // attribute for the header or content to open folds at initialization
      initialOpenAttribute: 'data-open',
      // whether to use transition at initial open
      initialOpenTransition: true,
      // delay used to show initial transition
      initialOpenTransitionDelay: 200,
      // header/content class if fold is open
      headerOpenClass: 'accordion__header--open',
      contentOpenClass: 'accordion__content--open',
      // header/content class if fold has been opened (transition finished)
      headerOpenedClass: 'accordion__header--opened',
      contentOpenedClass: 'accordion__content--opened',
      // header/content class if fold has been focused
      headerFocusClass: 'accordion__header--focus',
      contentFocusClass: 'accordion__content--focus',
      // header/content class if fold is disabled
      headerDisabledClass: 'accordion__header--disabled',
      contentDisabledClass: 'accordion__content--disabled',
      // header/content class if no transition should be active (applied on resize)
      headerNoTransitionClass: 'accordion__header--notransition',
      contentNoTransitionClass: 'accordion__content--notransition',
    });
  }
}

function initialise() {
  // Initialize the document.
  initialiseInside(document);

  // Set a function on the window in order to be accessible to the MVC AJAX callback.
  window.initialiseAccordionsInsideSelector = selector =>
    initialiseInside(document.querySelector(selector));
}

whenDomReady().then(initialise);
