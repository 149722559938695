
import SimpleBar from 'SimpleBar';
import whenDomReady from 'when-dom-ready';

function initialiseProducts() {
  const scrollSelector = document.querySelector('.js-scrollbar');

  if (!scrollSelector) {
    return;
  }

  // eslint-disable-next-line no-unused-vars
  const simpleBar = new SimpleBar(scrollSelector, { autoHide: false });
}

whenDomReady().then(initialiseProducts);
