import whenDomReady from 'when-dom-ready';

function checkDatalayerOnSubmission() {
  const currentLocation = window.location.href;
  // get sessionStorage.tannkyouUrl if equals currentLocation, then push datalayer
  const thankyouUrl = sessionStorage.getItem('thankyouUrl');
  if (thankyouUrl && thankyouUrl === currentLocation) {
    const datalayer = sessionStorage
      .getItem('datalayer')
      .replace(/'/g, '"')
      .replace(/([a-zA-Z0-9]+):/g, '"$1":');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(JSON.parse(datalayer));
    sessionStorage.removeItem('thankyouUrl');
    sessionStorage.removeItem('datalayer');
  }
}

whenDomReady().then(checkDatalayerOnSubmission);
