/** Get the siblings that match the selector */
export default function getSiblings(element, selector = null) {
  const siblings = Array.from(element.parentElement.children);

  if (!selector) {
    return siblings;
  }

  return siblings.filter(el => el.matches(selector));
}
