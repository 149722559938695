import whenDomReady from 'when-dom-ready';

function initialise() {
  const year = new Date().getFullYear();
  const copyright = document.querySelector('.footer .copyright');
  if (copyright) {
    copyright.innerHTML = `&copy; ${year} Fieldfisher. All rights reserved.`;
  }
}

whenDomReady().then(initialise);
