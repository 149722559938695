import getSiblings from './getSiblings';

/** Get the first sibling that matches the selector */
export default function getSibling(element, selector = null) {
  const siblings = getSiblings(element, selector);

  if (siblings && siblings.length) {
    return siblings[0];
  }

  return null;
}
