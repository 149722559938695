import whenDomReady from 'when-dom-ready';

import MultiLevelNavigation from './navigation/MultiLevelNavigation';

import getSibling from '@utils/getSibling';

function iOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

function initialise() {
  const navMain = document.querySelector('.js-nav-main');

  if (navMain) {
    const navigation = new MultiLevelNavigation(navMain, {
      activeClass: 'is-active',
      backSelector: '.js-nav-back',
      blockSelector: '.js-nav-block',
      browseSelector: '.js-nav-browse',
      closeSelector: '.js-nav-close',
      itemSelector: '.js-nav-item',
      linkSelector: '.js-nav-link',
      overlayElement: document.querySelector('.js-overlay-fade'),
      toggleButtonElement: document.querySelector('.js-nav-toggle'),
      scrollableSelector: '.js-nav-scrollable',
    });

    // make sure items with subitems click into the sub items rather than go to a page.
    document.querySelectorAll('.js-nav-link').forEach(el => {
      const browseButton = getSibling(el, '.js-nav-browse');
      if (browseButton) {
        el.addEventListener('click', event => {
          event.preventDefault();
          browseButton.click();
        });
      }
    });

    const btnToggle = document.querySelector('.js-nav-toggle');

    if (btnToggle) {
      let eventType = 'click';
      if (iOS()) {
        eventType = 'touchend';
      }
      btnToggle.addEventListener(eventType, event => {
        event.preventDefault();
        navigation.toggleNavigation();
        event.stopImmediatePropagation();
        return false;
      });
    }
  }
}

whenDomReady().then(initialise);
