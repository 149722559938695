class EventDispatcher {
  /**
   * Create a custom {Event} object.
   * @param typeArg
   * @param eventInitDict
   * @returns {Event}
   */
  createEvent(typeArg, eventInitDict = {}) {
    let event;

    if (typeof CustomEvent === 'function') {
      // modern browsers
      event = new CustomEvent(typeArg, {
        bubbles: eventInitDict.bubbles || false,
        cancelable: eventInitDict.cancelable || false,
        detail: eventInitDict.detail || '',
      });
    } else {
      // ie11
      event = document.createEvent('Event');
      event.initEvent(typeArg, false, false);
      // Manually assign detail if necessary
      if ('detail' in eventInitDict) {
        event.detail = eventInitDict.data;
      }
    }

    return event;
  }

  /**
   * Dispatch a custom {Event} from the target DOM element.
   * @param typeArg
   * @param eventInitDict
   * @param target
   */
  dispatchEvent(typeArg, eventInitDict = {}, target = window) {
    const event = this.createEvent(typeArg, eventInitDict);
    target.dispatchEvent(event);
  }
}

export default new EventDispatcher();
